import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./CaseHistory.scss";
import Item from "./Item/Item";
import query from "./query";
import PropTypes from "prop-types";
import {
  filterDataByLanguage,
  filterListDataByLanguage,
} from "../../Utils/Language";

const CaseHistory = (props) => {
  const data = query();
  const caseHistoryData = filterDataByLanguage(
    props.lang,
    data.allContentfulCaseHistoryList
  );
  const caseHistoryItemsData = filterListDataByLanguage(
    props.lang,
    data.allContentfulCaseHistoryItem
  );

  return (
    <div>
      <div className="history-container">
        <Container>
          <Row className="pb-lg-5 pb-0 history-row history-row-top">
            <Col className="col-xl-6 col-lg-6 col-12 history-title">
              {caseHistoryData.title.title}
            </Col>
            <Col className="col-xl-6 col-lg-6 col-12 history-description">
              {caseHistoryData.description.description}
            </Col>
          </Row>

          {caseHistoryItemsData && <Item itemData={caseHistoryItemsData} />}
        </Container>
      </div>
    </div>
  );
};

CaseHistory.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default CaseHistory;
