import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import "./Item.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

/**
 * CaseHistory List Item
 * Using a trick to render two elements for every row
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Item = (props) => {
  let rowContents = [];
  const contents = props.itemData.reduce((res, item, index) => {
    rowContents.push(
      <Col
        key={item.customerName + "_" + index}
        className="col-xl-6 col-lg-12 col-12 pb-xl-0 mb-xl-0 mb-5 history-image-col"
      >
        <Link to={item.customerLink}>
          <div className="history-image-box">
            <GatsbyImage
              className="history-image"
              image={getImage(item.customerImage)}
              alt={item.customerImage.title}
            />
          </div>
          <div className="history-box">
            <Row>
              <Col className="col-xl-9 col-lg-9 col-8 d-flex text-start align-items-center history-image-text">
                {item.customerName}
              </Col>
              {item.customerLink ? (
                <Col className="col-xl-2 col-lg-2 col-2 text-end d-flex align-items-center justify-content-lg-end me-xl-5 pe-4 history-image-text">
                  <i className="px-arrow-right" />
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Col className="col-xl-12 col-12 pt-xl-3 pt-4 history-box-description">
                {item.customerDescription.customerDescription}
              </Col>
            </Row>
          </div>
        </Link>
      </Col>
    );
    if (index % 2 === 1) {
      res.push(<Row key={item.id + "_" + index}>{rowContents}</Row>);
      rowContents = [];
    }
    return res;
  }, []);
  contents.push(
    <div key={1} className="row">
      {rowContents}
    </div>
  );

  return <span>{contents}</span>;
};

Item.propTypes = {
  itemData: PropTypes.object.isRequired,
};

export default Item;
