import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query {
      allContentfulCaseHistoryList {
        nodes {
          node_locale
          description {
            description
          }
          title {
            title
          }
        }
      }
      allContentfulCaseHistoryItem(sort: { fields: order }) {
        nodes {
          node_locale
          id
          customerName
          order
          customerLink
          customerDescription {
            customerDescription
          }
          customerImage {
            gatsbyImageData
            title
            file {
              url
            }
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
    }
  `);
};

export default query;
